

























import Vue from "vue";
import DataContainer from "@vuetifyx/common/DataContainer";
import coreApiClient from "@/services/apis/coreApiClient";
import downloadBase64File from "@/utils/downloadBase64File";
import { tags } from "@/constants/products";
import { printerTags } from "@/constants/settings";

export default Vue.extend({
  data() {
    return {
      dataContainer: new DataContainer(),
      formOptions: {
        attrs: {
          title: "Setting",
        },
        content: {
          sections: {
            default: {
              fields: {
                qzTrayCertificate: {
                  type: "textLarge",
                  attrs: {
                    label: "QZ Tray Certificate",
                  },
                },
                qzTrayPrivateKey: {
                  type: "textLarge",
                  attrs: {
                    label: "QZ Tray Private Key",
                  },
                },
                printerItems: {
                  type: "XArrayInput",
                  attrs: {
                    label: "Printers",
                    xOptions: {
                      content: {
                        itemLabel: (item) => {
                          return item.name;
                        },
                        itemProperties: {
                          name: {
                            attrs: {
                              label: "Name",
                              required: true,
                            },
                          },
                          tags: {
                            type: "selectMultiple",
                            attrs: {
                              label: "Tags",
                              items: printerTags.map((item) => ({
                                value: item,
                                text: item,
                              })),
                            },
                          },
                          productTags: {
                            type: "selectMultiple",
                            attrs: {
                              label: "Product Tags",
                              items: tags.map((item) => ({
                                value: item,
                                text: item,
                              })),
                            },
                          },
                          description: {
                            type: "textLarge",
                            attrs: {
                              label: "Description",
                            },
                          },
                        },
                        defaultExpandDisabled: true,
                        template: {
                          formOptions: {
                            content: {
                              colLength: 12,
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
          actionButtons: {
            save: {
              content: {
                text: "Save",
              },
              on: {
                async xClick({ self }) {
                  const form = self.context();
                  const data = form.getData();
                  form.loading.value = true;
                  self.loading.value = true;
                  await coreApiClient.call("setting", "update", undefined, {
                    data,
                  });
                  self.loading.value = false;
                  form.loading.value = false;
                  form.success.value = "Setting saved successfully";
                },
              },
            },
          },
        },
      },
      formContainer: new DataContainer(),
    };
  },
  methods: {
    async downloadPrintCertificate() {
      downloadBase64File(
        btoa(this.dataContainer.value.qzTrayCertificate),
        "print-certificate.crt",
        "application/pkix-cert"
      );
    },
  },
  async created() {
    const data = await coreApiClient.call("setting", "get");
    this.dataContainer.value = data;
  },
});
